.containerBodyModal {
    height: 40vh;
}

.component-card {
    max-width: 300px;
    min-width: 300px;
    break-inside: avoid-column;
    margin-bottom: 20px;
}

.component-card:hover {
    color: #0a58ca;
    border-color: #0a5dc2;
}

.sidebarBorder {
    overflow-y: auto;
    overflow-x: hidden;
}

.design-card {
    height: 300px;
    width: 400px;
    min-height: 300px;
    min-width: 300px;
    break-inside: avoid-column;
    margin-bottom: 20px;
}

.design-card-image {
    max-height: 250px;
}

.design-card:hover {
    color: #0a58ca;
    border-color: #0a5dc2;
}

.active-card {
    color: #0d822c !important;
    border-color: #0d822c !important;
}

@media (max-width: 1536px) {
    .design-card {
        height: 250px;
        width: 350px;
        min-height: 250px;
        break-inside: avoid-column;
        margin-bottom: 20px;
    }
}

@media (max-width: 1280px) {
    .design-card {
        height: 200px;
        width: 300px;
        min-height: 200px;
        break-inside: avoid-column;
        margin-bottom: 15px;
    }
}

@media (max-width: 1024px) {
    .design-card {
        height: 200px;
        width: 100%;
        min-height: 200px;
        break-inside: avoid-column;
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 575.98px){

}



/* Webkit browsers (Chrome, Safari) */
.sidebar-scroll::-webkit-scrollbar {
    width: 5px; /* Width of the scrollbar */
}

.sidebar-scroll::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
}

.sidebar-scroll::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Round the corners of the scrollbar thumb */
    border: 3px solid #f1f1f1; /* Space around the scrollbar thumb */
}

.sidebar-scroll::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb when hovered */
}

/* Firefox */
.sidebar-scroll {
    scrollbar-width: thin; /* Thin scrollbar */
    scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
}

/* Edge, IE 10+ */
.sidebar-scroll {
    -ms-overflow-style: -ms-autohiding-scrollbar; /* Autohide scrollbar */
}
