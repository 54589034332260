* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

* > #loom-companion-mv3 {
    display: none;
}

a {
    text-decoration: none;
    border: 0;
}

.cEMZEX {
    z-index: -1;
}

.toast-body {
    color: white;
}

.db_header {
    padding: 25px 0;
}

.db_card_content {
    width: 100%;
    padding: 24px;
    border: 1px solid #000000;
    border-radius: 16px;
}

.db_card_content.first_child {
    background: #cafee2;
}

.db_card_content.second_child {
    background: #cee2ff;
}

.db_card_content.third_child {
    background: #e8fff2;
}

.db_card_content.Fourth_child {
    background: #e5ecf6;
}

.card_icon {
    flex: 0 0 10%;
    width: 10%;
    color: #fff;
}

.card_icon svg {
    width: 100%;
    height: 100%;
}

.card_title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #474747;

    padding-bottom: 8px;
}

.card_subtitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #1c1c1c;
}

.card_icons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.card_icons svg {
    width: 24px;
    height: 24px;
}

.children_wrapper .rs-nav-horizontal {
    border-bottom: 1px solid #d9d9d9;
}

.children_wrapper .rs-nav-horizontal .rs-nav-item a {
    color: #4f4f4f;
}

.children_wrapper .rs-nav-horizontal .rs-nav-item.rs-nav-item-active {
    border-bottom: 4px solid #53B680;
    border-radius: 0;

}

.children_wrapper .rs-nav-horizontal .rs-nav-item.rs-nav-item-active a {
    color: #000000;
}

@media (min-width: 1600px) {
    .col-xxxl-1 {
        flex: 0 0 auto;
        width: 8.33333333%;
    }

    .col-xxxl-2 {
        flex: 0 0 auto;
        width: 16.66666667%;
    }

    .col-xxxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }

    .col-xxxl-4 {
        flex: 0 0 auto;
        width: 33.33333333%;
    }

    .col-xxxl-5 {
        flex: 0 0 auto;
        width: 41.66666667%;
    }

    .col-xxxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }

    .col-xxxl-7 {
        flex: 0 0 auto;
        width: 58.33333333%;
    }

    .col-xxxl-8 {
        flex: 0 0 auto;
        width: 66.66666667%;
    }

    .col-xxxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }

    .col-xxxl-10 {
        flex: 0 0 auto;
        width: 83.33333333%;
    }

    .col-xxxl-11 {
        flex: 0 0 auto;
        width: 91.66666667%;
    }

    .col-xxxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
}

@media (max-width: 576px) {
}

@media (max-width: 768px) {
    .dashboard_section {
        padding: 0;
    }
}

@media (max-width: 992px) {
    .db_header > .row {
        margin-bottom: -15px;
    }

    .db_header > .row > * {
        padding-bottom: 15px;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 1400px) {
}


.masonry {
    column-count: 4;
    margin: 1.5em;
    padding: 0;
    column-gap: 1.5em;
    font-size: .85em;
}

.floating-language-btn {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 40%;
    transition: opacity 0.2s ease-in;
}

.floating-language-btn:hover {
    opacity: 100%;
}

@media only screen and (max-width: 520px) {
    .masonry {
        column-count: 1;
    }
}

@media only screen and (min-width: 521px) and (max-width: 768px) {
    .masonry {
        column-count: 2;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .masonry {
        column-count: 3;
    }
}

@media only screen and (min-width: 1201px) {
    .masonry {
        column-count: 4;
    }
}

@import "./common.css";

