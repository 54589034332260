@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/card";

.misc-page {
  background: linear-gradient(0deg, rgba(83, 182, 128, 1) 0%, rgba(181, 222, 199, 1) 0%, rgba(211, 228, 226, 1) 100%);
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .misc-content {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 600px;
    }

  }

  .misc-image {
    max-width: 100px;
    max-height: 100px;
    @include media-breakpoint-up(sm) {
      max-width: 200px;
      max-height: 200px;
    }
    @include media-breakpoint-up(md) {
      max-width: 300px;
      max-height: 300px;
    }
  }
}
