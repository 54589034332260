.media-edit {
  position: relative;
  border: 1px dashed #ced4da;
  background-color: #eeeeee;
  border-radius: 5px;
  width: 100%;
  height: 100%;


  display: flex;
  align-items: center;
  justify-content: center;

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close-btn {
    position: absolute;
    top: -10px;
    border: 1px dotted red;
    right: -10px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    z-index: 5;

    .close-btn-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: red;
    }
  }

  &.error {
    border-color: red;
    background-color: #ffdddd;

    img {
      opacity: 30%;
      filter: invert(black, 100%);
    }
  }
}


