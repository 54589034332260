/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700,800&display=swap');*/
/*@import url('https://fonts.google.com/share?selection.family=Montserrat:ital,wght@0,100..900;1,100..900|Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900');*/
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700,800&display=swap');*/
--root{
    --font-Montserrat : 'Montserrat', sans-serif;
}


.limiter {
    width: 100%;
    margin: 0 auto
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1
}

.container-login100::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* background: #005bea; */
    background: linear-gradient(0deg, rgba(83, 182, 128, 1) 0%, rgba(181, 222, 199, 1) 0%, rgba(211,228,226,1) 100%);
    background: -webkit-linear-gradient(bottom, #00ea46, #00c6fb);
    background: -o-linear-gradient(bottom, #005bea, #00c6fb);
    background: -moz-linear-gradient(bottom, #005bea, #00c6fb);
    background: linear-gradient(bottom, #005bea, #00c6fb);
    opacity: .9
}

.wrap-login100 {
    width: 450px;
    background: 0 0
}

.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1
}

#input100 {
    font-family: var(--font-Montserrat );
    font-weight: 700;
    font-size: 15px;
    line-height: 1.2;
    color: #333;
    display: block;
    width: 100%;
    background: #fff;
    height: 50px;
    border-radius: 25px;
    padding: 0 30px 0 53px
}

.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0 0;
    color: rgba(0, 91, 234, .6)
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

#login100-form-btn {
    font-family: var(--font-Montserrat );
    font-weight: 700;
    font-size: 15px;
    line-height: 1.5;
    color: #e0e0e0;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #333;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    position: relative;
    z-index: 1
}

.login100-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    top: 0;
    left: 0;
    background: #005bea;
    background: -webkit-linear-gradient(left, #005bea, #00c6fb);
    background: -o-linear-gradient(left, #005bea, #00c6fb);
    background: -moz-linear-gradient(left, #005bea, #00c6fb);
    background: linear-gradient(left, #005bea, #00c6fb);
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    opacity: 0
}

.login100-form-btn:hover {
    background: 0 0;
    color: #fff
}

.login100-form-btn:hover:before {
    opacity: 1
}

.input100:focus,
.focus-input100 {
    /* -webkit-animation: anim-shadow .5s ease-in-out forwards;
    animation: anim-shadow .5s ease-in-out forwards */
    border: 0;
    outline-color: transparent;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0 0 80px 30px;
        opacity: 0
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0 0 80px 30px;
        opacity: 0
    }
}

.symbol-input100 {
    font-size: 15px;
    color: #999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 30px;
    pointer-events: none;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s
}

.input100:focus+.focus-input100+.symbol-input100 {
    color: #00c6fb;
    padding-left: 23px
}

.login100-form {
    width: 75%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin:auto
}

.login100-form-title {
    font-family: var(--font-Montserrat );
    font-weight: 800;
    font-size: 35px;
    color: #fff;
    line-height: 1.2;
    text-align: center;
    width: 102%;
    display: block
}

.validate-input {
    position: relative
}


.authError {
    text-align: center;
    color: red;
    font-weight: bolder;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 14px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;
    font-family: var(--font-Montserrat );
    font-weight: 700;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: url("./image/icon.svg");
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 55%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

.pwd{
    cursor: pointer;
    position: absolute;
    width: 20px;
    right: 30px;
    top: 15px;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}

@media(max-width: 576px) {
    .wrap-login100 {
        padding-top: 80px;
        padding-left: 15px;
        padding-right: 15px
    }
}