#generalComponentList {
  .rdt_TableCol:first-child,
  .rdt_TableCell:first-child,
  .rdt_TableCol:nth-child(2),
  .rdt_TableCell:nth-child(2) {
    position: sticky;
    left: 0;
    z-index: 1;
    background: inherit;
  }

  .rdt_TableCol:nth-child(2),
  .rdt_TableCell:nth-child(2) {
    left: 48px;
  }
}