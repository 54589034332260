@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/variables";

.item-builder {
  background-color: #FFF8E1;
  .item {
    &.dragged {
      background-color: #e1f5fe;
    }
  }
}
.ondrag {
  background-color: #eef8ff
}

.item-add {
  position: relative;
  background: none;
  display: block;
  width: 100%;
}

.item-add:hover > .item-add-content {
  opacity: 100%;
}


.item-add > .item-add-content {
  z-index: 100;
  opacity: 0;
  background-color: #eeeeee;
  transition: all 0.2s ease-in;
  background-color: #e1f5fe;
}

.item-add > .item-add-divider {
  height: 10px;
}


.item-add > .item-add-content.item-add-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.item-add > .item-add-content.item-add-btn:after {
  display: block;
  content: '+';
  font-size: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.item-add:hover > .item-add-divider {
  margin: 10px 0;
}

.new-item-btn {
  width: 100%;
  margin: 10px 0;
  height: 300px;
  padding: 20px 10px;
  background-color: #eceff1;
  border: 2px dashed #37474f;
}

.new-item-btn:hover {
  background-color: #e1f5fe;
  border-color: #01579b;
}
